<template>
  <div class="space-y-4">
    <Loading v-if="isLoading" />
    <template v-else>

      <div v-if="sortedSets.length > 0">
        <IndivSetExplore v-for="set in sortedSets" :key="set.track_id" :set-information="set.set_data" class="py-2">
          <div class="flex gap-2">
            <span class="text-sm text-white/50">
              {{ returnHumanizedDate(set.liked_at) }}
            </span>
          </div>
        </IndivSetExplore>
      </div>
      <div v-else class="flex justify-center items-center h-64">
        <div class="flex flex-col">
          <h2 class="mt-10 text-white text-center text-2xl font-bold leading-9 tracking-tight opacity-25 ">
            no likes.
          </h2>
          <h2 class="text-white text-center text-md font-bold leading-9 tracking-tight opacity-25 ">
            go and find your favorites.
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRuntimeConfig } from 'nuxt/app'
import { PlayIcon, } from '@heroicons/vue/24/solid'
import { useStore } from '@/store'


let store = useStore()
let props = defineProps({
  likes: {
    type: Array,
    default: null
  }
})
const config = useRuntimeConfig()

const sets = ref([])
const currentPage = ref(1)
const itemsPerPage = 20
const totalItems = ref(0)
const isLoading = ref(true)

const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage))

const sortedSets = computed(() => {
  return [...sets.value].sort((a, b) => new Date(b.played_at) - new Date(a.played_at))
})


function returnHumanizedDate(incomingDate) {
  console.log("[aphex] incoming date", incomingDate)
  // Create a new Date object from the input date string
  const date = new Date(incomingDate);
  const now = new Date();

  // Calculate the difference in minutes
  const diffInMinutes = Math.floor((now - date) / (1000 * 60));

  // If less than 2 minutes ago, return "just now"
  if (diffInMinutes < 2 && diffInMinutes >= 0) {
    return "just now";
  }

  // If less than 30 minutes ago, return actual minutes ago
  if (diffInMinutes < 30 && diffInMinutes >= 2) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  }

  // Define options for the humanized format
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  // Use Intl.DateTimeFormat to format the date and time
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  // Check if the date is today
  const isToday = date.toDateString() === now.toDateString();

  // Construct the humanized date string
  let humanizedDate = `${formattedDate} at ${formattedTime}`;
  if (isToday) {
    humanizedDate = `Today at ${formattedTime}`;
  }

  return humanizedDate;
}

const fetchSets = async () => {
  isLoading.value = true
  const offset = (currentPage.value - 1) * itemsPerPage
  try {

    await store.getLikes().then((fetchedLikesResponse) => {
      console.log("Store Incoming likes", fetchedLikesResponse)
      sets.value = fetchedLikesResponse;
      totalItems.value = fetchedLikesResponse.total_count;
    })

  } catch (error) {
    console.error('Error fetching sets:', error)
  } finally {
    isLoading.value = false
  }
}

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
  }
}

const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

watch(currentPage, () => {
  fetchSets()
})

if (props.likes) {
  sets.value = props.likes
  isLoading.value = false
} else {
  // Initial fetch
  fetchSets()
}
</script>