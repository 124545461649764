<template>
  <div class="space-y-4">
    <Loading v-if="isLoading" />
    <template v-else>

      <div v-if="sortedSets.length > 0"> 
        <IndivSetExplore v-for="set in sortedSets" :key="set.track_id" :set-information="set.set_data" class="py-2">
          <div class="flex gap-2">
            <span class="text-sm text-white/50">
              {{ returnHumanizedDate(set.played_at) }}
            </span>
          </div>
        </IndivSetExplore>
      </div>
      <div v-else class="flex justify-center items-center h-64">
        <div class="flex flex-col">
          <h2 class="mt-10 text-white text-center text-2xl font-bold leading-9 tracking-tight opacity-25 ">
            empty.
          </h2>
          <h2 class="text-white text-center text-md font-bold leading-9 tracking-tight opacity-25 ">
            go and listen to some sets.
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRuntimeConfig } from 'nuxt/app'
import { PlayIcon, } from '@heroicons/vue/24/solid'
import { returnHumanizedDate } from '~/utils/timestamps'

let props = defineProps({
  recentlyPlayed: {
    type: Array,
    default: null
  }
})

const config = useRuntimeConfig()

const sets = ref([])
const currentPage = ref(1)
const itemsPerPage = 30
const totalItems = ref(0)
const isLoading = ref(true)

const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage))

const sortedSets = computed(() => {
  return [...sets.value].sort((a, b) => new Date(b.played_at) - new Date(a.played_at))
})




const fetchSets = async () => {
  isLoading.value = true
  const offset = (currentPage.value - 1) * itemsPerPage
  try {


    let fetchRecentlyPlayedRequest = await supabaseFetch(
      `/user/activity/view-recently-played/sets?limit=${itemsPerPage}&offset=${offset}`,
      {
        method: "GET",
      }
    )
    let fetchedRecentlyPlayedResponse = await fetchRecentlyPlayedRequest.json();
    console.log("got recently played", fetchedRecentlyPlayedResponse)
    sets.value = fetchedRecentlyPlayedResponse;
    totalItems.value = fetchedRecentlyPlayedResponse.total_count;
  } catch (error) {
    console.error('Error fetching sets:', error)
  } finally {
    isLoading.value = false
  }
}

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
  }
}

const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

watch(currentPage, () => {
  fetchSets()
})

// Initial fetch

if (props.recentlyPlayed) {
  sets.value = props.recentlyPlayed
  isLoading.value = false
} else {
  fetchSets()
}
</script>